<template>

  <div>

    <!-- Table Container Card -->
    <b-card
        no-body
        class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>显示</label>
            <v-select
                v-model="limit"
                :options="limitOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
            />
            <label>条</label>
          </b-col>

          <!-- Search -->
          <b-col
              cols="12"
              md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  @click="advanced_search"
              >
                <feather-icon
                    icon="SearchIcon"
                    class="mr-50"
                />
                <span class="align-middle">高级搜索</span>
              </b-button>

            </div>
          </b-col>
        </b-row>

        <!-- Search -->
        <b-card v-if="isShowCard">
          <b-form
              @submit.prevent="searchByCondition"
              @reset.prevent="resetCondition"
          >
            <b-row>
              <!--核销编号-->
              <b-col md="3">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="核销编号"
                    label-for="ve_no"
                    label-size="sm"
                    class="mb-1"
                >
                  <b-form-input
                      id="ve_no"
                      size="sm"
                      v-model="condition.ve_no"
                      placeholder="请输入核销编号"
                  />
                </b-form-group>
              </b-col>
              <!--收款编号-->
              <b-col md="3">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="收款编号"
                    label-for="receive_no"
                    label-size="sm"
                    class="mb-1"
                >
                  <b-form-input
                      id="receive_no"
                      size="sm"
                      v-model="condition.receive_no"
                      placeholder="请输入收款编号"
                  />
                </b-form-group>
              </b-col>
              <!--店铺团队-->
              <b-col md="3">
                <modal-select
                    label="店铺团队"
                    type="input"
                    v-on:change="fromChildren($event,['store_name','store_id'],condition)"
                    :params="['store_name','store_id']"
                    modalName="店铺"
                    placeholder="点击选择店铺团队"
                    v-model="condition.store_name"
                >
                </modal-select>
              </b-col>
              <!--审核状态-->
              <b-col md="3">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="审核状态"
                    label-for="status"
                    label-size="sm"
                    class="mb-1"
                >
                  <v-select
                      id="status"
                      :options="getCodeOptions('verification_status')"
                      v-model="condition.status"
                      class="select-size-sm"
                      placeholder="请选择审核状态"
                      :reduce="option => option.value"
                  />
                </b-form-group>
              </b-col>
              <b-col
                  cols="12"
                  md="12"
                  class=" demo-inline-spacing d-flex align-items-center justify-content-end"
              >
                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    type="submit"
                >
                  <span class="align-right">查询</span>
                </b-button>
                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    type="reset"
                >
                  <span class="align-right">重置</span>
                </b-button>

              </b-col>
            </b-row>
          </b-form>
        </b-card>
      </div>

      <b-table
          ref="refListTable"
          class="position-relative"
          :items="searchList"
          responsive
          hover
          small
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="orderBy"
          show-empty
          empty-text="未找到记录"
          :sort-desc.sync="isSortDirDesc"
      >
        <!--        <template #row-details="row">
                  <statementProxyVerificationItemList :ve_id="row.item.ve_id"></statementProxyVerificationItemList>
                </template>-->

        <!-- Columns -->
        <template #cell(ve_id)="data">
          <!--          <b-form-checkbox v-model="data.detailsShowing"
                                     name="check-button"
                                     @change="data.toggleDetails"
                                     button-variant="default"
                                     size="sm"
                                     button>
                      <feather-icon
                          icon="MinusSquareIcon"
                          size="16"
                          class="align-middle text-body"
                          v-show="data.detailsShowing==true"
                      />
                      <feather-icon
                          icon="PlusSquareIcon"
                          size="16"
                          class="align-middle text-body"
                          v-show="data.detailsShowing==false"
                      />
                    </b-form-checkbox>-->
          #{{ data.item.ve_id }}
        </template>

        <template #cell(ve_no)="data">
          <b-link
              :to="{ name: 'apps-statementproxyverification-edit', query: { id: data.item.id ,ids:data.item.ext.finance_ids.split(','),store_id:data.item.store_id,store_name:data.item.store_name} }"
          >
            {{ data.item.ve_no }}
          </b-link>
        </template>

        <template #cell(creator)="data">
          {{ getCodeLabel('user', data.item.creator) }}
          <br>{{ toTime(data.item.add_time) }}
        </template>


        <template #cell(status)="data">
          <b-badge
              pill
              :variant="`light-${getCodeColor('verification_status', data.item.status)}`"
          >
            {{ getCodeLabel('verification_status', data.item.status) }}
          </b-badge>

        </template>

        <!--备注-->
        <template #cell(memo)="data">
          <div :id="`memo-${data.item.id}`">
            <span v-if="data.item.memo">
              {{ data.item.memo.substring(0, 6) }}
              <span v-if="data.item.memo.length>6">...</span>
            </span>
          </div>
          <b-tooltip :target="`memo-${data.item.id}`" placement="top">
            {{ data.item.memo }}
          </b-tooltip>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
              variant="link"
              no-caret
          >

            <template #button-content>
              <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
              />
            </template>
<!--            <b-dropdown-item @click="audit(data.item.id,2)" v-if="data.item.status == 1">
              <feather-icon icon="FileTextIcon"/>
              <span class="align-middle ml-50">提交审核</span>
            </b-dropdown-item>-->
            <b-dropdown-item @click="del(data.item.id)" v-if="data.item.status == 1">
              <feather-icon icon="Trash2Icon"/>
              <span class="align-middle ml-50">删除</span>
            </b-dropdown-item>
<!--            <b-dropdown-item @click="audit(data.item.id,3)"
                             v-if="data.item.status == 2 && [11,12,14,27].includes(user.role_id)">
              <feather-icon icon="CheckIcon"/>
              <span class="align-middle ml-50">审核通过</span>
            </b-dropdown-item>
            <b-dropdown-item @click="audit(data.item.id,1)"
                             v-if="data.item.status == 2 && [11,12,14,27].includes(user.role_id)">
              <feather-icon icon="XIcon"/>
              <span class="align-middle ml-50">驳回</span>
            </b-dropdown-item>-->

          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">显示 {{ dataMeta.from }} - {{ dataMeta.to }} / {{ dataMeta.of }} 记录</span>
          </b-col>
          <!-- Pagination -->
          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
                v-model="start"
                :total-rows="listTotals"
                :per-page="limit"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import {ref, onUnmounted, toRefs} from '@vue/composition-api'
import {avatarText, toTime, toDate, getCodeOptions, getCode, getCodeLabel, getCodeColor} from '@core/utils/filter'
import statementproxyverificationUseList from './statementproxyverificationUseList'
import statementproxyverificationStore from './statementproxyverificationStore'
import axios from "@/libs/axios";
import {useToast} from "vue-toastification/composition";
import Ripple from "vue-ripple-directive";
import ModalSelect from "@/views/components/modal/ModalSelect";
import statementProxyVerificationItemList
  from "@/views/apps/statementproxyverificationitem/StatementProxyVerificationItemList";
import {getUserData} from "@/auth/utils";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    ModalSelect,
    statementProxyVerificationItemList,
  },
  directives: {
    Ripple,
  },
  created() {
    const userData = getUserData()
    this.user = userData
  },
  methods: {
    changeState: function (data) {
      const state = 1 - data.state
      store.dispatch('statementproxyverification/state', {id: data.id, state: state}).then(res => {
        data.state = state
      })
    },
  },
  data() {
    return {
      isShowCard: false,
      rangeConfig: {
        mode: 'range',
        maxDate: 'today',
      },
      user: {},
    }
  },
  setup() {
    const toast = useToast()
    // Register module
    if (!store.hasModule('statementproxyverification')) store.registerModule('statementproxyverification', statementproxyverificationStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('statementproxyverification')) store.unregisterModule('statementproxyverification')
    })

    const audit = function (id, status) {
      axios.post('/api/statementproxyverification/audit', {id: id, status: status}).then(res => {
        if (res.data.code == 0) {
          toast.success('已更新')
          refetchData()
        }else {
          toast.error(res.data.data)
        }
      })
    }

    const searchByCondition = function () {
      refetchData()
    }

    const resetCondition = function () {
      this.condition = {}
      refetchData();
    }

    const fromChildren = function (params, modal, item) {
      for (let i = 0; i < modal.length; i++) {
        item[modal[i]] = params == null ? null : params[modal[i]]
      }
      this.$forceUpdate()
    }

    const advanced_search = function () {
      this.isShowCard = !this.isShowCard
    }

    const del = function (id) {
      this.$swal({
        title: '确定删除吗',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        buttonsStyling: false,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
      }).then(result => {
        if (result.value) {
          axios.post('/api/statementproxyverification/delById', {id: id}).then(res => {
            if (res.data.code == 0) {
              this.$swal({
                icon: 'success',
                title: '已删除!',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
              //重新加载表格
              refetchData()
            } else {
              toast.error(res.data.data)
            }
          })
        }
      })

    }

    const {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      condition,
      // UI
    } = statementproxyverificationUseList()

    return {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      audit,
      condition,
      searchByCondition,
      resetCondition,
      fromChildren,
      advanced_search,
      del,
      // Filter
      avatarText,
      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      // UI
      toTime,
      toDate,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
